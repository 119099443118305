import React, { useRef, useEffect, useState } from 'react';
import Navbar from './navbar';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { startAccountDeletion } from '../store/schemas/authSlice';
import { useDispatch } from 'react-redux';
import { cancelAccountDeletion } from '../store/schemas/authSlice';


// import { Helmet } from 'react-helmet';

const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userActive = useSelector(state => state.auth.userActive)

  useEffect(() => {
    dispatch(cancelAccountDeletion())
  }, [])

  return (
    <>

      <Navbar />
      <section className='hero-banner '>
        {/* <video autoPlay loop muted playsinline>
                <source src={herovideo} type='video/mp4' />
            </video> */}

        <img src={'/static/assets/pavlos-stamatopoulos-z36jGtPfGyc-unsplash.webp'
        } alt='hero-banner' />
        <div className='hero-text vivify fadeIn duration-300 delay-400'>
          <h1>Empower, Participate, Donate: The Future of School Fundraising</h1>
          <p>Innovative, user-centric, and effective. Welcome to a new era of fundraising.</p>
          <div className='btn-group'>

            <button onClick={
              () => {
                userActive ? navigate('/app', { target: '_blank' }) :
                  navigate('/app/onboarding', { target: '_blank' });
              }
            } className='btn'>Campaign Dashboard</button>

            <button onClick={
              () => {
                window.location.href = 'mailto:support@schoolsensefundraising.com'
              }

            } className='btn'>Contact Us</button>
          </div>
        </div>
      </section>

      <section id='about-us' className='about-us vivify fadeIn duration-300 delay-700' style={
        {
          backgroundImage: `url(/assets/aboutusstencil.png)`,
        }
      }>
        <div className='about-us-text'>
          <h2>About Us</h2>
          <p>
            Schoo$ense is a comprehensive, user-friendly fundraising platform tailored to meet the unique needs of educational institutions. We pride ourselves on recognizing and honoring each school's distinctive identity, and creating customized strategies that align with their specific objectives.
          </p>
          <p>
            Our goal is to make every dollar count, maximizing the impact on the quality of education offered to students.
          </p>
        </div>
        <div className='about-us-img'>
          <img src={'/assets/studentimage.jpg'} alt='about-us-img' />
        </div>
      </section>
      <style>
        {
          `
    #root {display: flex; flex-direction:column}
    `
        }
      </style>

      <footer>
        <p>BCS Fundraising</p>



        <a href="/legal/PrivacyPolicy.pdf" className='legal-link'>Privacy Policy</a>

        <a href="/legal/TermsAndConditions.pdf" className='legal-link'>Terms of Service</a>

        <a onClick={
          () => {

            navigate('/legal')
          }

        } className='legal-link'>
          Do Not Sell or Share My Personal Information
        </a>

      </footer>

    </>
  );

}

export default LandingPage;